<template>
  <div class="columns is-mobile non-printable">
    <div class="column is-narrow has-text-left">
      <!-- <button v-if="activeStep !== 0" :class="{button: true, 'button-left': true, 'hidie-first': activeStep !== 0 }" v-on:click="goToPrevStep"> -->
      <button :class="{button: true, 'button-left': true, 'hidie-first': activeStep == 0 }" v-on:click="goToPrevStep">
          <span class="is-hidden-mobile">{{ $t('generic.back') }}</span>
      </button>
    </div>
    <div class="column">
      <h1 class="title is-size-4 is-size-3-tablet is-uppercase">{{name}}</h1>
    </div>
    <div class="column is-narrow has-text-right">
      <button :class="{button: true, 'button-right': true, 'hidie-first': activeStep == steps.length-1 }" @click="goToNextStep" :disabled="disableNext">
        <span class="is-hidden-mobile">{{ $t('generic.next') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step-nav',
  props: {
    name: String,
    validStep: Boolean,
    // nextCriteria: Array,
    // evalCriteria: String,
    // evalErrors: Boolean
  },
  data: function () {
    return {}
  },
  computed: {
    validSteps() {
      let steps = this.steps
      let validSteps = []
      steps.forEach(step => {
        let validFields = step.requierdFileds.length
        step.requierdFileds.forEach(reqField => {
          let fieldValue = this.$store.getters.getSelectedValue(reqField)
          if (!fieldValue || fieldValue.length === 0) validFields --
        })
        if (step.requiredType === 'single') {
          if (validFields > 0) validSteps.push(step.id)
        } else {
          if (validFields === step.requierdFileds.length) validSteps.push(step.id)
        }
      })
      return validSteps
    },
    steps () {
      return this.$store.getters.getSteps
    },
    activeStep () {
      return this.$store.getters.getActiveStep
    },
    disableNext () {
      if (this.activeStep === this.steps.length - 2) {
        return this.validSteps.length !== this.steps.length
      }
      return !this.validStep
      // let approvedCriterias = []
      // if (this.evalErrors) return true
      // if (this.nextCriteria === undefined || this.nextCriteria.length === 0) return false

      // this.nextCriteria.forEach(condition => {
      //   let selectedValue = this.$store.getters.getSelectedValue(condition)
      //   if (selectedValue === null) return 
      //   if (selectedValue.length > 0 && selectedValue !== "0") approvedCriterias.push(condition)
      // })
      // if (this.evalCriteria === 'single' && approvedCriterias.length === 0) {
      //   return true
      // } else if (this.evalCriteria !== 'single' && approvedCriterias.length < this.nextCriteria.length) {
      //   return true 
      // } else {
      //   return false
      // } 
    },
  },
  methods: {
    goToPrevStep() {
      this.$store.dispatch('previousStep')
    },
    goToNextStep() {
      this.$store.dispatch('nextStep')
    }
  },
}
</script>

<style>
.hidie-first {
  visibility: hidden
}
@media print {
  .non-printable {
    display: none
  }
}
</style>
