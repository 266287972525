var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "columns is-mobile non-printable" }, [
    _c("div", { staticClass: "column is-narrow has-text-left" }, [
      _c(
        "button",
        {
          class: {
            button: true,
            "button-left": true,
            "hidie-first": _vm.activeStep == 0,
          },
          on: { click: _vm.goToPrevStep },
        },
        [
          _c("span", { staticClass: "is-hidden-mobile" }, [
            _vm._v(_vm._s(_vm.$t("generic.back"))),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "column" }, [
      _c(
        "h1",
        { staticClass: "title is-size-4 is-size-3-tablet is-uppercase" },
        [_vm._v(_vm._s(_vm.name))]
      ),
    ]),
    _c("div", { staticClass: "column is-narrow has-text-right" }, [
      _c(
        "button",
        {
          class: {
            button: true,
            "button-right": true,
            "hidie-first": _vm.activeStep == _vm.steps.length - 1,
          },
          attrs: { disabled: _vm.disableNext },
          on: { click: _vm.goToNextStep },
        },
        [
          _c("span", { staticClass: "is-hidden-mobile" }, [
            _vm._v(_vm._s(_vm.$t("generic.next"))),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }